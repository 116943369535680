/* eslint-disable */
import {API, Auth, Storage} from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import {uploadFiles} from './S3Storage';
import {loadProfiles} from "./App";

export const DCA = 'classification';
export const SPA = 'splitting';
export const EEA = 'extraction';
export const ActiveTaskStates = ['PROCESSING', 'TRAINING', 'COMPLETED', 'TRAINED', 'TRAINING_VERIFICATION', 'DISCARDED', 'DELETED', 'ERROR'];

const useVerificationCases = true;

async function getRequestHeaders (headers) {
  const session = await Auth.currentSession();
  const options = {
    headers: Object.assign({}, {
      // Activate following headers when connecting to a local instance of Modelbuilder
      // 'x-caller-id': 'local:CognitoIdentityCredentials',
      // 'cognito-authentication-type': 'authenticated',
      // 'x-iam-user': 'local:CognitoIdentityCredentials',
      // 'x-cognito-type': 'authenticated',
      // 'x-cognito-id': 'id',
      // 'Content-Type': 'application/json',
      'bs-token': `bearer ${session.idToken.jwtToken}`
    }, headers)
  };
  return options;
}

export async function getModels (type, headers = {}, sourceMlModelId, page = 0, size = 1000, sortBy = 'displayName', sortOrder = 'asc') {
  const params = await getRequestHeaders(headers);
  params.queryStringParameters = {
    page,
    size,
    sort: sortBy + ',' + sortOrder
  };
  if(sourceMlModelId) params.queryStringParameters.sourceMlModelId = sourceMlModelId;
  let path = type ? `/${type}-models` : '/models';
  return await API.get('ModelbuilderAPI', path, params);
}

/**
 * splitting-version-info
 * classification-version-info
 * extraction-version-info
 */
export async function getModelVersionInfo(type, profileNames, modelNames, filter, headers = {}) {
    const params = await getRequestHeaders(headers);
    let mergedResult = {};
    const chunkSize = 10;

    if (profileNames?.length > 0 || modelNames?.length > 0) {
        params.queryStringParameters = {};
    }
    if (profileNames?.length > 0) {
        params.queryStringParameters.profileNames = profileNames;
    }
    if (filter) {
        params.queryStringParameters.filter = filter;
    }
    const path = '/' + (type ? (type + '-version-info') : 'model-info');

    if(modelNames?.length > 0) {
        const modelNamesArray = modelNames.split(',').map(name => name.trim());
        const modelNamesSet = new Set(modelNamesArray);
        const modelNamesList = Array.from(modelNamesSet);

        for (let i = 0; i < modelNamesList.length; i += chunkSize) {
            const chunk = modelNamesList.slice(i, i + chunkSize);
            params.queryStringParameters.modelNames = chunk.join(',');
            const response = await API.get('ModelbuilderAPI', path, params);
            mergedResult = mergeResults(mergedResult, response);
        }
    } else {
        const response = await API.get('ModelbuilderAPI', path, params);
        mergedResult = mergeResults(mergedResult, response);
    }

    return mergedResult;
}

function mergeResults(target, source) {
    if (!source.profiles) return target;
    source.profiles.forEach(sourceProfile => {
        if (!target.profiles) target.profiles = [];
        const targetProfile = target.profiles.find(profile => profile.profileName === sourceProfile.profileName);

        if (targetProfile) {
            mergeProfile(targetProfile, sourceProfile);
        } else {
            target.profiles.push(sourceProfile);
        }
    });
    return target;
}

function mergeProfile(targetProfile, sourceProfile) {
    ['models', 'newModels', 'modelInstances', 'revisedModelInstances'].forEach(key => {
        if (sourceProfile[key]) {
            targetProfile[key] = (targetProfile[key] || []).concat(sourceProfile[key]);
        }
    });
}

export async function getAllModelsForDocument (modelName, modelTypes, headers = {}) {
  const request = await getRequestHeaders(headers);
  const name = modelName;
  if (modelTypes) {
    request.queryStringParameters = {
      modelTypes: modelTypes
    };
  }
  try {
    return await API.get('ModelbuilderAPI', `/document-classes/${name}/models`, request);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function restartDocuments (tenant, documentIds, profile, documentFilter) {
  return processDocuments(tenant, documentIds, { activeTaskStates: ['COMPLETED'], ...documentFilter }, 'START_DATA_VERIFICATION_ASYNC', { targetProfileName: profile });
}

export async function deleteDocuments (tenant, documentIds, documentFilter) {
  return processDocuments(tenant, documentIds, { activeTaskStates: ActiveTaskStates.filter(state => state !== 'DELETED'), ...documentFilter }, 'DELETE_TRAINING_DATA');
}

export async function cancelDocuments (tenant, documentIds, documentFilter) {
  return processDocuments(tenant, documentIds, { activeTaskStates: ['TRAINING_VERIFICATION'], ...documentFilter }, 'CANCEL_TRAINING_PROCESS');
}

export async function activateDocuments (tenant, documentIds, documentFilter) {
  return processDocuments(tenant, documentIds, { activeTaskStates: ['DEACTIVATED'], ...documentFilter }, 'ACTIVATE_TRAINING_DATA');
}

export async function deactivateDocuments (tenant, documentIds, documentFilter) {
  return processDocuments(tenant, documentIds, { activeTaskStates: ActiveTaskStates.filter(state => state !== 'DEACTIVATED' && state !== 'DELETED' && state !== 'DISCARDED'), ...documentFilter }, 'DEACTIVATE_TRAINING_DATA');
}

export async function processDocuments (tenant, documentIds, documentFilter, action, body) {
  const request = await getRequestHeaders();
  if (tenant) { set(request.headers, 'X-Tenant-Id', tenant.tenantId); }
  request.body =
  {
    action,
    ...body
  };
  if (documentIds && documentIds.length > 0) {
    request.body.documentIds = documentIds;
  } else {
    request.body.documentFilter = documentFilter;
  }
  const path = '/documents';
  return await API.patch('ModelbuilderAPI', path, request);
}

export async function getDocuments (tenant, page = 0, size = 10, sortBy = 'createdOn', sortDir = 'desc', filters, aggregate = true) {
  const options = await getRequestHeaders({});
  if (tenant) { set(options.headers, 'X-Tenant-Id', tenant.tenantId); }
  options.queryStringParameters = {
    page,
    size,
    sort: sortBy + ',' + sortDir,
    aggregate,
    ...filters
  };
  const path = '/documents';
  return await API.get('ModelbuilderAPI', path, options);
}

/**
 * POST model distributions - e.g. /modelbuilder/extraction-models/INVOICE/distributions
 * @param {String} sourceTenantId
 * @param {String} sourceModelName
 * @param {String} targetModelName
 * @param {String} targetModelDisplayName
 * @param {String} sourceMlModelVersion
 * @param {String} sourceModelVersion
 * @param {String} sourceProfileName
 * @param {String} serviceId
 * @param {Object} profileConfig
 * @param {Object} tenant
 * @returns
 */
export async function distributeModel (sourceTenantId, sourceModelName, targetModelName, targetModelDisplayName, sourceMlModelVersion, sourceModelVersion, sourceProfileName, serviceId, profileConfig, tenant) {
  const request = await getRequestHeaders();
  if (tenant) { set(request.headers, 'X-Tenant-Id', tenant.tenantId); }
  const path = `/${getModelTypeFromServiceId(serviceId)}-models/${sourceModelName}/distributions`;

  request.body = {
    targetModelName: targetModelName,
    targetModelDisplayName,
    sourceTenantId,
    sourceMlModelVersion,
    sourceModelVersion,
    sourceProfileName,
    profileConfig
  };

  return await API.post('ModelbuilderAPI', path, request);
}

/**
 * @param {String} serviceId
 * @returns {String}
 */
function getModelTypeFromServiceId (serviceId) {
  serviceId = serviceId.toUpperCase();
  switch (serviceId) {
    case 'EEA':
      return 'extraction';
    case 'DCA':
      return 'classification';
    case 'DSA':
      return 'splitting';
    case 'SPA':
      return 'splitting';
    default:
      throw new Error('invalid argument');
  }
}

/**
 * Operator only
 */
export async function loadAllModelsForTenant (tenant) {
  const request = await getRequestHeaders();
  set(request.headers, 'X-Tenant-Id', tenant.tenantId);
  const path = '/models';
  return await API.get('ModelbuilderAPI', path, request);
}

export async function getNewTrainedUndeployedModels (profiles) {
  if(profiles){
    const profileNames = profiles?.filter(profile => profile.profileName !== 'COMMON' && profile.profileName !== 'SHOP')?.map(profile => profile.profileName)?.join(',');
    const infos = await getModelVersionInfo(null, profileNames, null, 'EXCLUDE_MODEL_VERSION_INFOS');
    if(infos){
        return infos.newModels;
    }
  }
}

export async function getArchivedInstances (instance, headers) {
  return instance && instance.modelStatus !== 'NOT_DEPLOYED' ? await getModelInstances(instance, 'ARCHIVED', instance.profile, headers) : null;
}

export async function getInstances (sourceModelIds, modelType, status, profileName, headers) {
  const params = await getRequestHeaders(headers);
  params.queryStringParameters = {};
  if (status) params.queryStringParameters.status = status;
  if (profileName) params.queryStringParameters.profileName = profileName;
  if (sourceModelIds) params.queryStringParameters.sourceModelIds = sourceModelIds;

  const type = modelType.toLowerCase().replace('_instance', '');
  const path = `/${type}-instances`;
  try {
    return await API.get('ModelbuilderAPI', path, params);
  } catch (error) {
    return [];
  }
}

export async function getPublishedModelInstance (name, modelType, profileName, headers) {
  const params = await getRequestHeaders(headers);
  params.queryStringParameters = {};
  params.queryStringParameters.status = 'PUBLISHED';
  params.queryStringParameters.profileName = profileName;

  const type = modelType.toLowerCase().replace('_instance', '');
  const path = `/${type}-models/${name}/instances`;
  try {
    const instances = await API.get('ModelbuilderAPI', path, params);
    return instances?.length > 0 ? instances[0] : undefined;
  } catch (error) {
    return [];
  }
}

export async function getModelInstances (template, status, profileName, headers) {
  const params = await getRequestHeaders(headers);
  params.queryStringParameters = {};
  if (status) params.queryStringParameters.status = status;
  if (profileName) params.queryStringParameters.profileName = profileName;

  const type = template.modelType.toLowerCase().replace('_instance', '');
  const path = `/${type}-models/${template.name}/instances`;
  try {
    return await API.get('ModelbuilderAPI', path, params);
  } catch (error) {
    return [];
  }
}

export async function requestModelTraining (name, type, headers) {
  const params = await getRequestHeaders(headers);
  const path = `/${type.toLowerCase()}-models/${name}/training-request`;
  params.body = {};
  return await API.post('ModelbuilderAPI', path, params);
}

export async function getModel (name, type, headers = {}) {
  const params = await getRequestHeaders(headers);
  const path = `/${type.toLowerCase().replace('_instance', '')}-models/${name}`;
  return await API.get('ModelbuilderAPI', path, params);
}

async function postDCAModel (appConfigs, model, organization) {
  return postModel(appConfigs, DCA, model, organization);
}

async function postSPAModel (appConfigs, model, organization) {
  return postModel(appConfigs, SPA, model, organization);
}

async function postEEAModel (appConfigs, model, organization) {
  return postModel(appConfigs, EEA, model, organization);
}

async function postModel (appConfigs, type, model, organization) {
  model.profileConfig = buildProfileConfig(type, appConfigs, organization);

  const request = await getRequestHeaders();
  request.body = model;
  const path = `/${type}-models`;

  return await API.post('ModelbuilderAPI', path, request);
}

export async function patchModel (appConfigs, type, modelDto) {
  modelDto.profileConfig = buildProfileConfig(type, appConfigs);

  const request = await getRequestHeaders();
  request.body = modelDto;
  const path = getPathByModelType(type) + '/' + modelDto.name;

  return await API.patch('ModelbuilderAPI', path, request);
}

async function updateDCAModel (appConfigs, name, model, organization, headers) {
  return updateModel(appConfigs, DCA, name, model, organization, headers);
}

async function updateSPAModel (appConfigs, name, model, organization, headers) {
  return updateModel(appConfigs, SPA, name, model, organization, headers);
}

async function updateEEAModel (appConfigs, name, model, organization, headers) {
  return updateModel(appConfigs, EEA, name, model, organization, headers);
}

async function updateModel (appConfigs, type, name, model, organization, headers) {
  const trainingDataAction = model.profileConfig?.trainingDataAction;
  model.profileConfig = buildProfileConfig(type, appConfigs, organization, trainingDataAction);
  const request = await getRequestHeaders(headers);
  request.body = { ...model };
  if(request.body.confidenceThreshold === undefined){
    request.body.confidenceThreshold = 85;
  }
  cleanUpModel(request.body);
  const path = `/${type}-models/${name}`;
  return await API.put('ModelbuilderAPI', path, request);
}

export function buildProfileConfig (type, appConfigs, organization, trainingDataAction) {
  const serviceName = getServiceNameByModelType(type.toUpperCase());
  const profileName = 'COMMON';
  const profileConfig = getProfileConfig(appConfigs, serviceName, profileName, organization);

  return {
    profileName: null,
    customerId: profileConfig.customerId,
    apiCustomerId: profileConfig.apiCustomerId,
    externalCustomerId: profileConfig.externalCustomerId,
    trainingDataAction
  };
}

export function cleanUpModel (model) {
  delete model.readOnly;
  delete model.templateIsOutdated;
  delete model.mlModelIsOutdated;
  delete model.isTemplate;
  delete model.isTemplateDeployed;
  delete model.isMlModelDeployed;
  delete model.isNewTemplateVersionAvailable;
  delete model.isNewMlModelVersionAvailable;
  delete model.isNewMlModelVersionDeployed;
  delete model.isNewTemplateVersionDeployed;
  delete model.serviceName;
  delete model.className;
  delete model.numberOfDocsInModel;
  delete model.numberOfDocClasses;
  delete model.quality;
  delete model.numberOfDocsForNextTraining;
  delete model.isOutdated;
  delete model.updateModelAction;
  delete model.lastModifiedByDisplay;
  delete model.profiles;
  delete model.profile;
  delete model.profileHistory;
  delete model.action;
  delete model.callbackEditModel;
  delete model.callbackViewModel;
  delete model.callbackTrainModel;
  delete model.origin;
  delete model.status;
  delete model.templateModel;
  delete model.instanceInfos;
  if (model.entityGroups) {
    model.entityGroups.forEach(group => {
      delete group.icon;
      if (group.entities) {
        group.entities.forEach(entity => {
          delete entity.quality;
        });
      }
    });
  }
}

export async function publishMlModel (model, profileName) {
  const name = model.name;
  const type = model.modelType.toLowerCase();
  const params = await getRequestHeaders();
  params.queryStringParameters = {
    profileName: profileName,
    action: 'ASSIGN_CURRENT_ML_MODEL'
  };
  const path = `/${type}-models/${name}/instances`;
  return await API.patch('ModelbuilderAPI', path, params);
}
export async function publishTemplate (model, profileName, context) {
  await publishModel(model, profileName, context);
}

export async function publishModel (model, profileName, context, modelVersion, mlModelVersion, tenantId) {
  const serviceName = getServiceNameByModelType(model.modelType);
  const profileConfig = getProfileConfig(context.apps, serviceName, profileName, null);
  const profiles = await loadProfiles(undefined, false);
  const name = model.name;

  const profileToPublish = profiles.find(profile => profile?.profileName === profileName);
  const isTrainingProfile = profileToPublish ? profileToPublish?.params?.isTraining : false;

  let trainingDataAction;
  if(isTrainingProfile) {
    trainingDataAction = "DISCARD_TRAINING_DATA";
  } else {
    trainingDataAction = model.profileConfig?.trainingDataAction;
  }
  const type = model.modelType.toLowerCase().replace('_instance', '');
  let path = `/${type}-models/${name}/instances`;

  const request = await getRequestHeaders();

  if (tenantId) set(request.headers, 'X-Tenant-Id', tenantId);

  request.queryStringParameters = {
    action: 'PUBLISH'
  };

  if (modelVersion) {
    path += `/${mlModelVersion}.${modelVersion}`;
    request.queryStringParameters = {
      action: 'PUBLISH',
      profileName: profileName
    };
    request.body = {};
  } else {
    request.body = {
      profileName: profileName,
      customerId: context.user.organization.orgId,
      apiCustomerId: profileConfig.apiCustomerId,
      externalCustomerId: context.user.organization.customerId,
      trainingDataAction
    };
  }

  return await API.post('ModelbuilderAPI', path, request);
}

export async function publishModelNew (model, profileName, customerId, apiCustomerId, externalCustomerId, modelVersion, mlModelVersion, tenantId) {
  const name = model.name;
  const type = model.modelType.toLowerCase().replace('_instance', '');
  let path = `/${type}-models/${name}/instances`;

  const request = await getRequestHeaders();
  if (tenantId) set(request.headers, 'X-Tenant-Id', tenantId);

  request.queryStringParameters = {
    action: 'PUBLISH'
  };

  if (modelVersion) {
    path += `/${mlModelVersion}.${modelVersion}`;
    request.queryStringParameters.profileName = profileName;
    request.body = {};
  } else {
    request.body = {
      profileName,
      customerId,
      apiCustomerId,
      externalCustomerId
    };
  }

  return await API.post('ModelbuilderAPI', path, request);
}

export async function putModelInstanceProfileConfig (modelName, modelType, mlModelVersion, modelVersion, profileName, profileConfig, tenantId, modelConfig) {
  const type = modelType.toLowerCase().replace('_instance', '');
  let path = `/${type}-models/${modelName}/instances/${mlModelVersion}.${modelVersion}/profiles/${profileName}`;
  const request = await getRequestHeaders();

  if (tenantId) set(request.headers, 'X-Tenant-Id', tenantId);

  const profiles = await loadProfiles(undefined, false);

  const profileToPublish = profiles.find(profile => profile?.profileName === profileName);
  const isTrainingProfile = profileToPublish ? profileToPublish?.params?.isTraining : false;

  let trainingDataAction;
  if(isTrainingProfile) {
    trainingDataAction = "DISCARD_TRAINING_DATA";
  } else {
    trainingDataAction = profileConfig?.trainingDataAction;
  }

  profileConfig.trainingDataAction = trainingDataAction;

  request.body = {
    profileConfig,
    modelConfig
  };

  return await API.put('ModelbuilderAPI', path, request);
}

export async function postDistributions (distributionRequests, tenantId) {
  const request = await getRequestHeaders();
  if (tenantId) set(request.headers, 'X-Tenant-Id', tenantId);
  request.body = {
    distributionRequests
  };
  return await API.post('ModelbuilderAPI', '/model-distributions', request);  
}

export function getServiceNameByModelType (modelType) {
  if (modelType === 'EXTRACTION' || modelType === 'EXTRACTION_INSTANCE') return 'EEA';
  if (modelType === 'CLASSIFICATION' || modelType === 'CLASSIFICATION_INSTANCE') return 'DCA';
  if (modelType === 'SPLITTING' || modelType === 'SPLITTING_INSTANCE') return 'SPA';
  return null;
}

/**
 *
 * @param {string} serviceName
 * @returns {string}
 */
export function getTypeByModelServiceName (serviceName) {
  serviceName = serviceName.toUpperCase();
  if (serviceName === 'EEA') return 'EXTRACTION';
  if (serviceName === 'DCA') return 'CLASSIFICATION';
  if (serviceName === 'SPA' || serviceName === 'DSA') return 'SPLITTING';
  return null;
}

export function getPathByModelType (modelType) {
  if (modelType === 'EXTRACTION' || modelType === 'EXTRACTION_INSTANCE') return '/extraction-models';
  if (modelType === 'CLASSIFICATION' || modelType === 'CLASSIFICATION_INSTANCE') return '/classification-models';
  if (modelType === 'SPLITTING' || modelType === 'SPLITTING_INSTANCE') return '/splitting-models';
  return null;
}

export async function deleteDCAModel (name) {
  return deleteModel(DCA, name);
}

export async function deleteSPAModel (name) {
  return deleteModel(SPA, name);
}

export async function deleteEEAModel (name) {
  return deleteModel(EEA, name);
}

export async function deleteModel (type, name) {
  const request = await getRequestHeaders();
  const path = `/${type}-models/${name}`;
  request.body = {};
  await API.del('ModelbuilderAPI', path, request);
}

export async function saveDcaModel (appConfigs, model, organization, headers) {
  if (!model) return;
  console.log(`Saving DCA model. model=${model}`);
  const isUpdate = !!model.id;
  enhanceDcaModel(model);
  if (isUpdate) {
    return updateDCAModel(appConfigs, model.name, model, organization, headers);
  } else {
    return postDCAModel(appConfigs, model, organization);
  }
}

export function enhanceDcaModel(model){
  if(!useVerificationCases) return;
  if(model.confidenceThreshold === undefined){
    model.confidenceThreshold = 85;
  }

  if(!model.verificationCases) model.verificationCases = [];

  // confidence
  const hasVerCase = !!model.verificationCases?.find(verCase => verCase.documentClassConfidence && !verCase.documentClass?.value);
  const verCase = model.verificationCases?.find(verCase => verCase.documentClassConfidence && !verCase.documentClass?.value) || {};
  //verCase.documentClass = { comparator: 'any' };
  verCase.documentClassConfidence = {
    comparator : 'lessThan',
    value : model.confidenceThreshold
  };
  if(!hasVerCase) model.verificationCases.push(verCase);

  // foreach class
  model.documentClasses?.forEach(clz => {
    const hasDocVerCase = !!model.verificationCases?.find(verCase => verCase.documentClassConfidence && verCase.documentClass?.value === clz.name);
    const docVerCase = model.verificationCases?.find(verCase => verCase.documentClassConfidence && verCase.documentClass?.value === clz.name) || {};
    if (clz.confidenceThreshold && clz.confidenceThreshold !== model.confidenceThreshold) {
      docVerCase.documentClass = { comparator: 'equal', value: clz.name };
      docVerCase.documentClassConfidence = { comparator: 'lessThan', value: clz.confidenceThreshold };
      if(!hasDocVerCase) model.verificationCases.push(docVerCase);
    } else if(hasDocVerCase){
      const idxEmptyValue = model.verificationCases.indexOf(docVerCase);
      if(idxEmptyValue > -1) model.verificationCases.splice(idxEmptyValue, 1);
    }
  });

  model.verificationCases = model.verificationCases.sort((a,b) => {
    if(a.documentClassConfidence && !a.documentClass?.value) return -1;
    if(b.documentClassConfidence && !b.documentClass?.value) return 1;
    return 0;
  });
}

export async function saveSpaModel (appConfigs, model, organization, headers) {
  if (!model) return;
  console.log(`Saving SPA model. Model=${model}`);
  const isUpdate = !!model.id;
  enhanceSpaModel(model);
  if (isUpdate) {
    return updateSPAModel(appConfigs, model.name, model, organization, headers);
  } else {
    return postSPAModel(appConfigs, model, organization);
  }
}

export function enhanceSpaModel(model){
  if(!useVerificationCases) return;
  if(model.confidenceThreshold === undefined){
    model.confidenceThreshold = 85;
  }

  if(!model.verificationCases) model.verificationCases = [];

  // confidence
  const hasVerCase = !!model.verificationCases?.find(verCase => verCase.splittingConfidence);
  const verCase = model.verificationCases?.find(verCase => verCase.splittingConfidence) || {};
  verCase.splittingConfidence = {
    comparator : 'lessThan',
    value : model.confidenceThreshold
  }
  if(!hasVerCase) model.verificationCases.push(verCase);

  model.verificationCases = model.verificationCases.sort((a,b) => {
    if(a.splittingConfidence) return -1;
    if(b.splittingConfidence) return 1;
    return 0;
  });
}

export async function saveEeaModel (appConfigs, model, organization, headers) {
  if (!model) return;
  const isUpdate = !!model.id;
  enhanceEeaModel(model);
  if (isUpdate) {
    if (model.documentClasses && Array.isArray(model.documentClasses) && model.documentClasses.length > 0 && model.documentClasses[0].displayName && model.displayName) {
      model.documentClasses[0].displayName = model.displayName;
    }
    return updateEEAModel(appConfigs, model.name, model, organization, headers);
  } else {
    return postEEAModel(appConfigs, model, organization);
  }
}

function enhanceEeaModel(model){
  if(model.confidenceThreshold === undefined){
    model.confidenceThreshold = 85;
  }
  model.entityGroups.forEach(group => group.entities.forEach(entity => {
    enhanceEntity(entity, model);
  }));
}

export function enhanceEntity(entity, model){
  if(!useVerificationCases) return;
  if(!entity.verificationCases) entity.verificationCases = [];

  // confidence
  const hasVerCase = !!entity.verificationCases?.find(verCase => verCase.entityConfidence);
  const verCase = entity.verificationCases?.find(verCase => verCase.entityConfidence) || {};
  if(entity.relevance !== 'OPTIONAL'){
    verCase.entityConfidence = {
      comparator : 'lessThan',
      value : entity.confidenceThreshold || model?.confidenceThreshold || 85,
      inherited : !entity.confidenceThreshold
    }
    if(!hasVerCase) entity.verificationCases.push(verCase);
  }else{
    const idxVer = entity.verificationCases.indexOf(verCase);
    if(idxVer > -1) entity.verificationCases.splice(idxVer, 1);
  }

  // empty values
  const hasEmptyValueCase = !!entity.verificationCases?.find(verCase => verCase.entityExistence);
  const emptyValueCase = entity.verificationCases?.find(verCase => verCase.entityExistence) || {};
  if(entity.relevance === 'MANDATORY'){
    emptyValueCase.entityExistence = {
      comparator : 'equal',
      value : false
    }
    if(!hasEmptyValueCase) entity.verificationCases.push(emptyValueCase);
  } else{
    const idxEmptyValue = entity.verificationCases.indexOf(emptyValueCase);
    if(idxEmptyValue > -1) entity.verificationCases.splice(idxEmptyValue, 1);
  }

  // invalid values
  const hasInvalidValueCase = !!entity.verificationCases?.find(verCase => verCase.entityValidation);
  const invalidValueCase = entity.verificationCases?.find(verCase => verCase.entityValidation) || {};
  if(!entity.relevance || entity.relevance === 'MANDATORY' || entity.relevance === 'STANDARD'){
    invalidValueCase.entityValidation = {
      comparator : 'equal',
      value: false
    }
    if(!hasInvalidValueCase) entity.verificationCases.push(invalidValueCase);
  } else{
    const idxInvalidValue = entity.verificationCases.indexOf(invalidValueCase);
    if(idxInvalidValue > -1) entity.verificationCases.splice(idxInvalidValue, 1);
  }

}

/**
 * @param {object} appConfigs
 * @param {string} serviceName
 * @param {object} selectedProfile
 * @param {object} organization
 * @returns {object}
 */
function getProfileConfig (appConfigs, serviceName, selectedProfile, organization) {
  const selectedProfileName = selectedProfile ? selectedProfile.name ? selectedProfile.name : selectedProfile : 'COMMON';
  const appConfig = getAppConfig(appConfigs, selectedProfileName);
  if (isEmpty(appConfig.apps)) {
    // If the profile has not been fully configured yet (most likely navigated away from profiles page and didn't refresh the page),
    // we insert the common configuration so that all actions have a valid apiCustomerId
    const commonAppConfig = getAppConfig(appConfigs, 'COMMON');
    appConfig.apps = commonAppConfig.apps;
  }
  const appConfigService = appConfig.apps.find(app => app.serviceName === serviceName);
  return {
    profileName: appConfig?.profileName ?? 'COMMON',
    customerId: organization?.orgId,
    apiCustomerId: getApiCustomerId(serviceName, appConfig),
    externalCustomerId: appConfigService?.externalCustomerId ?? organization?.customerId
  };
}

export function getCommonProfileConfig(appConfigs, serviceName, org) {
  return getProfileConfig(appConfigs, serviceName, 'COMMON', org)
}

function getAppConfig (apps, profileName) {
  const appConfig = apps.find(appConfig => (appConfig.profileName === profileName));
  if (!appConfig) {
    // Fallback: return first profile that has any apps or, if none exist, return first profile
    return apps.find(app => !!app.apps?.length) || apps[0];
  }
  return appConfig;
}

function getApiCustomerId (serviceName, appConfig) {
  const serviceConfig = (appConfig.apps) ? appConfig.apps.find(app => (app.serviceName === serviceName)) : null;
  return (serviceConfig) ? serviceConfig.customerId : 'NONE';
}

/**
 * Uploads Files to s3, path /{tenant-id}/training/DV/{service}, will be forwarded to verifier
 * @param {Object} context context
 * @param {String} serviceName Service EEA | DCA | SPA
 * @param {String} modelName EEA -> documentClass | DCA+SPA modelId
 * @param {FileList} files Filelist
 * @param {Function} progressCb
 * @param {String} profileName
 * @param {String} modelId
 * @returns {Promise<Object>}
 */
export async function uploadFilesToVerifier (context, serviceName, modelName, files, progressCb, profileName, modelId) {
  const userIdToken = context.user.signInUserSession.idToken.payload;
  const tenantId = userIdToken.tenantId;
  const userId = userIdToken.sub;
  console.log(`Uploading files to verifier for tenant=${tenantId}. Service=${serviceName}, files=${files}`);
  const keyPrefix = `training/DV/${serviceName}/${uuid()}`;
  const profileConfig = getProfileConfig(context.apps, serviceName, profileName || context.selectedProfile, null);
  const metadata = {
    tenantId,
    modelName,
    profile: profileConfig.profileName,
    profileConfig: JSON.stringify(profileConfig),
    serviceName,
    userId,
    modelId,
    DV: 'true'
  };
  if (!modelName) throw Error('Missing modelName - EEA: documentClass, DCA+SPA: modelId');
  return uploadFiles(tenantId, keyPrefix, files, metadata, progressCb, null, 'trainingsdata=true');
}

export function validateFilesToUpload (files) {
  const SUPPORTED_FILE_EXTENSIONS = ['pdf', 'tif', 'tiff', 'jpeg', 'jpg', 'png', 'eml'];
  const invalidFiles = [];
  for (const file of files) {
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (!SUPPORTED_FILE_EXTENSIONS.includes(fileExt.toLowerCase())) {
      invalidFiles.push(file);
    }
  }
  return invalidFiles;
}

/**
 * @param {Object} context context
 * @param {String} serviceName Service EEA | DCA | SPA
 * @param {String} modelName Modelname
 * @param {String}  documentClass Document Class, Only DCA
 * @param {FileList} files Filelist
 * @param {Function} progressCb
 * @param {Object} model
 * @returns {Promise<Object>}
 */
export async function uploadFilesForTraining (context, serviceName, modelName, documentClass, files, progressCb, model) {
  const userIdToken = context.user.signInUserSession.idToken.payload;
  const tenantId = userIdToken.tenantId;
  const userId = userIdToken.sub;
  console.log(`Uploading files for training for tenant=${tenantId}. Service=${serviceName}, docClass=${documentClass}, files=${files}`);
  let keyPrefix = `training/${serviceName}/${modelName}`;
  if (documentClass) { keyPrefix += `/${documentClass}`; }
  keyPrefix += `/${uuid()}`;
  const profileConfig = getProfileConfig(context.apps, serviceName, null, null);
  const metadata = {
    tenantId,
    modelName,
    profile: profileConfig.profileName,
    profileConfig: JSON.stringify(profileConfig),
    serviceName,
    userId,
    modelId: model.modelId,
    modelTemplateVersion: model.modelVersion + '',
    modelType: mapServiceToModelType(serviceName)
  };
  if (documentClass) {
    metadata.documentClass = documentClass;
  }
  return await uploadFiles(tenantId, keyPrefix, files, metadata, progressCb, documentClass, 'trainingsdata=true');
}

/**
 * @deprecated
 * @param {String} type SPLITTING | CLASSIFICATION | EXTRACTION
 * @param {String} name Name of the Model
 * @param {String} documentClass DocumentClass, only DCA
 * @param {String} s3Prefix Prefix of all files
 * @param {Object} profileConfig
 * @returns
 */
/* eslint-disable-next-line */
async function notifyUpload (type, name, documentClass, s3Prefix, profileConfig) {
  const request = await getRequestHeaders();
  request.body = { modelType: type, modelName: name, s3Path: s3Prefix, profileConfig: profileConfig };
  if (type === 'CLASSIFICATION') { request.body.documentClass = documentClass; }
  const path = '/dataset';
  return await API.post('ModelbuilderAPI', path, request);
}

export function mapServiceToModelType (service) {
  // extract|classify|split
  service = service.toUpperCase();
  switch (service) {
    case 'DCA':
      return 'CLASSIFICATION';
    case 'EEA':
      return 'EXTRACTION';
    case 'DSA':
      return 'SPLITTING';
    case 'SPA':
      return 'SPLITTING';
    default:
      throw new Error(`${service} is not supported`);
  }
}

export function configureStorage (tenantId) {
  Storage.configure({
    customPrefix: {
      public: `${tenantId}/protected/`
    }
  });
}

export async function loadDocumentClasses (modelType) {
  const params = await getRequestHeaders();
  params.queryStringParameters = {};
  if (modelType) params.queryStringParameters.modelTypes = modelType;

  const path = '/document-classes';
  const docClasses = await API.get('ModelbuilderAPI', path, params);
  if (docClasses) docClasses.sort(caseInsensitiveComparator);
  return docClasses;
}

export async function deleteDocumentClass (documentClass, documentClassType) {
  const name = documentClass.name ? documentClass.name : documentClass;
  const request = await getRequestHeaders();

  if (documentClassType) request.queryStringParameters.documentClassType = documentClassType;

  const path = `/document-classes/${name}`;
  request.body = {};
  return await API.del('ModelbuilderAPI', path, request);
}

export function caseInsensitiveComparator (valueA, valueB) {
  let valueALowerCase = valueA.displayName ? valueA.displayName.toLowerCase() : valueA.name ? valueA.name.toLowerCase() : valueA.toLowerCase();
  let valueBLowerCase = valueB.displayName ? valueB.displayName.toLowerCase() : valueB.name ? valueB.name.toLowerCase() : valueB.toLowerCase();

  if (valueALowerCase) valueALowerCase = valueALowerCase.replace('ü', 'ue').replace('ö', 'oe').replace('ä', 'ae');
  if (valueBLowerCase) valueBLowerCase = valueBLowerCase.replace('ü', 'ue').replace('ö', 'oe').replace('ä', 'ae');

  if (valueALowerCase < valueBLowerCase) {
    return -1;
  } else if (valueALowerCase > valueBLowerCase) {
    return 1;
  } else { // valueALowerCase === valueBLowerCase
    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    } else {
      return 0;
    }
  }
}

export async function getTrainingConfig (modelType, headers) {
  const params = await getRequestHeaders(headers);

  params.queryStringParameters = {
    type: modelType
  };

  const path = '/training-configs';
  const allConfigs = await API.get('ModelbuilderAPI', path, params);

  if (allConfigs && modelType) {
    const config = allConfigs.find(nextConfig => nextConfig.modelType === modelType);
    if (config && config.trainingIntervals) {
      return config;
    }
  }
  return allConfigs;
}

export async function saveTrainingConfig (trainingConfig, isCommon, headers) {
  const request = await getRequestHeaders(headers);
  if (isCommon) trainingConfig.tenantId = 'COMMON';
  request.body = trainingConfig;

  const isUpdate = !!trainingConfig.id;

  if (isUpdate) {
    const path = '/training-configs/' + trainingConfig.id;
    return await API.put('ModelbuilderAPI', path, request);
  } else {
    const path = '/training-configs';
    return await API.post('ModelbuilderAPI', path, request);
  }
}

export function getNextTrainingInterval (firstThreshold, trainingIntervals) {
  let secondThreshold = firstThreshold + 200;
  if (trainingIntervals) {
    const nextInterval = trainingIntervals.find(interval => interval > firstThreshold);
    if (nextInterval) secondThreshold = nextInterval;
    else {
      if (trainingIntervals.length >= 2) {
        const incrementor = trainingIntervals[trainingIntervals.length - 1] - trainingIntervals[trainingIntervals.length - 2];
        secondThreshold = trainingIntervals[trainingIntervals.length - 1];
        while (secondThreshold <= firstThreshold) {
          secondThreshold += incrementor;
        }
      }
    }
  }
  return Math.max(secondThreshold, 10);
}

function getProfileLabel (profiles, profileId) {
  const profile = profiles?.find(appConfig => appConfig.profileName === profileId);
  return profile?.params?.displayName;
}
/**
 *
 * @param {string} modelName
 */
export function validateModelName (modelName) {
  if (!modelName) {
    return { isValid: false, illegalChars: [] };
  }
  const illegalCharsStr = modelName.split(/[a-zA-Z0-9_]+/).reduce(function (prev, curr) {
    return curr ? prev + curr : prev;
  }, '');
  const illegalChars = new Set(illegalCharsStr.split(''));
  return { isValid: illegalChars.size === 0, illegalChars: Array.from(illegalChars.values()) };
}

export function enrichModelData (modelsData, skipProfiles, hasNewAiSolutionPermission, profiles, readOnly, sortKey = 'displayName') {
  if (!modelsData) { return []; }
  const extendedModels = modelsData.map(model => {
    if(skipProfiles && model?.className){
      return model;
    }
    const isInstance = model?.modelType?.toUpperCase().endsWith('_INSTANCE');
    const isTemplate = !isInstance;
    let templateModel = isTemplate ? model : model.templateModel;
    let templateIsOutdated = false;
    let mlModelIsOutdated = false;
    if (model.templateModel) {
      templateIsOutdated = model.templateModel.modelVersion && model.templateModel.modelVersion > 0 && (!model.modelVersion || model.templateModel.modelVersion > model.modelVersion);
      mlModelIsOutdated = model.templateModel.mlModelVersion && model.templateModel.mlModelVersion > 0 && (!model.mlModelVersion || model.templateModel.mlModelVersion > model.mlModelVersion);
    }
    const numberOfDocClasses = model.documentClasses ? model.documentClasses.length : undefined;
    let isTemplateDeployed = false;
    let isMlModelDeployed = false;
    let isNewTemplateVersionAvailable = false;
    let isNewMlModelVersionAvailable = false;
    let isNewTemplateVersionDeployed = false;
    let isNewMlModelVersionDeployed = false;

    if (templateModel && templateModel.profiles) {
      const profiles = templateModel.profiles.filter(profile => profile.profileConfig.profileName !== 'SHOP');
      const mlModelInstance = profiles.find(profile => (profile.mlModelVersion && profile.mlModelVersion > 0));
      const templateInstance = profiles.find(profile => (profile.modelVersion && profile.modelVersion > 0));
      isMlModelDeployed = !!mlModelInstance;
      isTemplateDeployed = !!templateInstance;
      const outdatedMlModelInstance = profiles.find(profile => ((!profile.mlModelVersion && templateModel.mlModelVersion && templateModel.mlModelVersion > 0) || profile.mlModelVersion < templateModel.mlModelVersion));
      const outdatedTemplateInstance = profiles.find(profile => ((!profile.modelVersion && templateModel.modelVersion && templateModel.modelVersion > 0) || profile.modelVersion < templateModel.modelVersion));
      const updatedMlModelInstance = profiles.find(profile => (profile.mlModelVersion && templateModel.mlModelVersion && profile.mlModelVersion === templateModel.mlModelVersion));
      const updatedTemplateInstance = profiles.find(profile => (profile.modelVersion && templateModel.modelVersion && profile.modelVersion === templateModel.modelVersion));
      isNewMlModelVersionAvailable = !!outdatedMlModelInstance;
      isNewTemplateVersionAvailable = !!outdatedTemplateInstance;
      isNewMlModelVersionDeployed = !!updatedMlModelInstance;
      isNewTemplateVersionDeployed = !!updatedTemplateInstance;
    } else if(templateModel && templateModel.instanceInfos){
      isMlModelDeployed = !!templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => inst.mlModelVersion > 0) || profile.revisedModelInstances?.find(inst => inst.mlModelVersion > 0) )
      isTemplateDeployed = !!templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => inst.modelVersion > 0) || profile.revisedModelInstances?.find(inst => inst.modelVersion > 0) )
      const outdatedMlModelInstance = templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => ((!inst.mlModelVersion && templateModel.mlModelVersion && templateModel.mlModelVersion > 0) || inst.mlModelVersion < templateModel.mlModelVersion)) || profile.revisedModelInstances?.find(inst => ((!inst.mlModelVersion && templateModel.mlModelVersion && templateModel.mlModelVersion > 0) || inst.mlModelVersion < templateModel.mlModelVersion))    );
      const outdatedTemplateInstance = templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => ((!inst.modelVersion && templateModel.modelVersion && templateModel.modelVersion > 0) || inst.modelVersion < templateModel.modelVersion)) || profile.revisedModelInstances?.find(inst => ((!inst.modelVersion && templateModel.modelVersion && templateModel.modelVersion > 0) || inst.modelVersion < templateModel.modelVersion)) );
      const updatedMlModelInstance = templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => (inst.mlModelVersion && templateModel.mlModelVersion && inst.mlModelVersion === templateModel.mlModelVersion)) || profile.revisedModelInstances?.find(inst => (inst.mlModelVersion && templateModel.mlModelVersion && inst.mlModelVersion === templateModel.mlModelVersion)) );
      const updatedTemplateInstance = templateModel.instanceInfos.find(profile => profile.modelInstances?.find(inst => (inst.modelVersion && templateModel.modelVersion && inst.modelVersion === templateModel.modelVersion)) ||  profile.revisedModelInstances?.find(inst => (inst.modelVersion && templateModel.modelVersion && inst.modelVersion === templateModel.modelVersion)));
      isNewMlModelVersionAvailable = !!outdatedMlModelInstance;
      isNewTemplateVersionAvailable = !!outdatedTemplateInstance;
      isNewMlModelVersionDeployed = !!updatedMlModelInstance;
      isNewTemplateVersionDeployed = !!updatedTemplateInstance;
    }
    let status = model.modelStatus === 'TRAINING_FAILED' || model.modelStatus === 'TRAINING_REQUESTED'
      ? 'TRAINING_STARTED'
      : model.modelStatus === 'PUBLISHED' && model.mlModelVersion === 0
        ? 'TEMPLATE_DEPLOYED'
        : model.modelStatus === 'CREATED' && isTemplateDeployed && !isMlModelDeployed
          ? 'TEMPLATE_DEPLOYED'
          : model.modelStatus === 'CREATED' && !isTemplateDeployed && !isMlModelDeployed
            ? 'NOT_DEPLOYED'
            : (model.modelStatus === 'CREATED' || model.modelStatus === 'TRAINED') && isTemplateDeployed && isMlModelDeployed
                ? 'DEPLOYED'
                : model.modelStatus;

    if (isTemplate && isNewMlModelVersionAvailable) status = 'TRAINED';

    let className = (isTemplate ? 'modelTemplate' : 'modelInstance') + ' ' + status;
    if (templateIsOutdated) className += ' templateIsOutdated';
    if (mlModelIsOutdated) className += ' mlModelIsOutdated';
    if (isNewTemplateVersionAvailable) className += ' newTemplateVersionAvailable';
    if (isNewMlModelVersionAvailable) className += ' newMlModelVersionAvailable';
    const profile = isInstance || model?.modelStatus?.toUpperCase() === 'NOT_DEPLOYED' ? model.profileConfig.profileName : model.profile ?? '';

    const isTemplateDeleted = model.templateModel && (model.templateModel.modelStatus === 'DELETED' || model.templateModel.modelStatus === 'MARKED_FOR_DELETION');
    const isTemplateDeactivated = model.templateModel && (model.templateModel.modelStatus === 'DEACTIVATED');

    const readOnlyAccess = readOnly || model.modelStatus === 'DELETED' || model.modelStatus === 'MARKED_FOR_DELETION' || model.accessPermission === 'READ';
    model.readOnly = readOnlyAccess;

    const lastModifiedByDisplay = model.lastModifiedBy ? model.lastModifiedBy.replace(/ \(.*\)$/, '') : undefined;

    if (model.templateModel) {
      const tempModel = enrichModelData([model.templateModel], true, hasNewAiSolutionPermission, profiles);
      templateModel = tempModel[0];
    }
    if (isTemplate) templateModel = undefined;

    const extendedModel = Object.assign({}, model, {
      templateModel,
      readOnly: readOnlyAccess,
      templateIsOutdated,
      mlModelIsOutdated,
      isTemplate,
      isTemplateDeployed,
      isMlModelDeployed,
      isNewTemplateVersionAvailable,
      isNewMlModelVersionAvailable,
      isNewMlModelVersionDeployed,
      isNewTemplateVersionDeployed,
      isTemplateDeleted,
      isTemplateDeactivated,
      serviceName: getServiceNameByModelType(model.modelType),
      profile,
      profileLabel: getProfileLabel(profiles, profile),
      profiles: !skipProfiles ? enrichModelData(model.profiles, false, hasNewAiSolutionPermission, profiles, readOnly, m => m.profileLabel?.toLowerCase()) : model.profiles,
      profileHistory: enrichModelData(model.profileHistory),
      className,
      modelVersion: model.modelVersion ? model.modelVersion : 0,
      mlModelVersion: model.mlModelVersion ? model.mlModelVersion : 0,
      lastModifiedByDisplay,
      numberOfDocsInModel: model.trainingData ? model.trainingData.numberOfTrainedDocs : 0,
      numberOfDocClasses: numberOfDocClasses,
      quality: !model.modelStatus || model.modelStatus === 'NOT_DEPLOYED' || !model.trainingData ? '0%' : Math.round(model.trainingData.qualityMetric * 100) + '%',
      numberOfDocsForNextTraining: model.trainingData && model.trainingData.availableDocs ? model.trainingData.availableDocs : 0,
      requiredDocsForNextTraining: model.trainingData ? model.trainingData.requiredDocsForNextTraining : 0,
      status,
      isOutdated: hasNewAiSolutionPermission && (mlModelIsOutdated || templateIsOutdated) && !isTemplateDeleted && !isTemplateDeactivated,
      updateModelAction: !readOnly && hasNewAiSolutionPermission && (mlModelIsOutdated || templateIsOutdated) && !isTemplateDeleted && !isTemplateDeactivated
    });
    return extendedModel;
  });
  return extendedModels;
}

export async function getGroupTemplates () {
  const request = await getRequestHeaders();
  const path = '/group-templates';
  return await API.get('ModelbuilderAPI', path, request);
}

export async function getGroupType (type, headers = {}) {
  const request = await getRequestHeaders(headers);
  const path = '/group-templates' + (type ? '/' + type : '');
  return await API.get('ModelbuilderAPI', path, request);
}

export async function createGroupTemplate (body) {
  const request = await getRequestHeaders();
  request.body = body;
  const path = '/group-templates';
  return await API.post('ModelbuilderAPI', path, request);
}

export async function updateGroupTemplate (type, body) {
  const request = await getRequestHeaders();
  request.body = body;
  const path = '/group-templates/' + type;
  return await API.put('ModelbuilderAPI', path, request);
}

export async function deleteGroupTemplate (type) {
  const request = await getRequestHeaders({'Content-Type': 'application/json'});
  request.body = {};
  const path = '/group-templates/' + type;
  return await API.del('ModelbuilderAPI', path, request);
}

export async function patchIndex (adminIndexRequest) {
  const request = await getRequestHeaders();
  const path = '/admin/documents';
  request.body = adminIndexRequest;
  return await API.patch('ModelbuilderAPI', path, request);
}

export async function callValidator (input, dataTypeConfig, dataType = 'STRING', trace = false, stringOutput = true) {
  const request = await getRequestHeaders();
  const path = '/entity-validations/analysis';

  request.body = {
    entities: [
      {
        dataTypeConfig,
        dataType,
        input,
        trace,
        stringOutput
      }
    ]
  };

  return await API.post('ModelbuilderAPI', path, request);
}

export async function copyModel(modelName, modelType, copyRequest, headers = {}) {
  const request = await getRequestHeaders(headers);
  const type = modelType.toLowerCase().replace('_instance', '');
  const path = `/${type}-models/${modelName}/copy`;

  request.body = copyRequest;

  return await API.post('ModelbuilderAPI', path, request);
}


export function spaModelToRules (model) {
  if(!useVerificationCases) return;
  const globalConditions = [];

  if(!model.verificationCases) model.verificationCases = [];

  model.verificationCases.forEach(cond => {
    if(cond.splittingConfidence?.value >= 0){
      globalConditions.push({
        id: cond.caseId,
        caseId: cond.caseId,
        splittingConfidence: cond.splittingConfidence,
        notSelectable: true
      });
    } else {
      globalConditions.push({
        id: cond.caseId, // uuid(),
        caseId: cond.caseId,
        splittingConfidence: { comparator: 'lessThan' },
        ...cond
      });
    }
  });

  return globalConditions;
}

export function dcaModelToRules (model) {
  if(!useVerificationCases) return;
  const globalConditions = [];

  if(!model.verificationCases) enhanceDcaModel(model);

  model.verificationCases.forEach(cond => {
    if(cond.documentClassConfidence){
      globalConditions.push({
        id: cond.caseId,
        caseId: cond.caseId,
        documentClass: cond.documentClass,
        documentClassConfidence: cond.documentClassConfidence,
        notSelectable: true
      });
    } else {
      globalConditions.push({
        id: cond.caseId, // uuid(),
        caseId: cond.caseId,
        documentClassConfidence: { comparator: 'lessThan' },
        ...cond
      });
    }
  });

  return globalConditions;
}

export function eeaModelToRules (model) {
  if(!useVerificationCases) return;
  const globalConditions = [];
  const modelNameExtraction = { comparator: 'equal', value: model?.name };

  const condition = {
    modelNameExtraction,
    dataField: { comparator: 'notOptionalWithoutOwnDefinition' },
    confidence: { comparator: 'lessThan', value: model?.confidenceThreshold ? model.confidenceThreshold : 85 },
    notSelectable: true,
    readOnly: true
  };
  globalConditions.push(condition);

  const invalidCondition = {
    modelNameExtraction,
    dataField: { comparator: 'notOptional' },
    confidence: { comparator: 'lessThan' },
    notSelectable: true,
    readOnly: true
  };
  invalidCondition.dataFieldValue = { comparator: 'invalid' };
  globalConditions.push(invalidCondition);

  const emptyCondition = {
    modelNameExtraction,
    dataField: { comparator: 'mandatory' },
    confidence: { comparator: 'lessThan' },
    notSelectable: true,
    readOnly: true
  };
  emptyCondition.dataFieldValue = { comparator: 'empty' };
  globalConditions.push(emptyCondition);

  model?.entityGroups?.forEach(group => group.entities?.forEach(entity => {
    if (entity.confidenceThreshold && ((model.confidenceThreshold && model.confidenceThreshold !== entity.confidenceThreshold) || (!model.confidenceThreshold && entity.confidenceThreshold !== 85))) {
      const condition = {
        modelNameExtraction,
        dataField: { comparator: 'equal', value: entity.name },
        confidence: { comparator: 'lessThan', value: entity.confidenceThreshold },
        notSelectable: true,
        readOnly: true
      };
      globalConditions.push(condition);
    }
    entity.verificationCases?.filter(verCase => verCase.entityValue)?.forEach(verCase => {
      const condition = {
        modelNameExtraction,
        dataField: { comparator: 'equal', value: entity.name },
        dataFieldValue: verCase.entityValue,
        confidence: { comparator: 'lessThan' },
        notSelectable: true,
        readOnly: true
      };
      globalConditions.push(condition);
    });
  }));
  return globalConditions;
}

export function isInstance (model) {
  return model?.modelType?.toUpperCase().endsWith('_INSTANCE');
}

export function isNotDeployed (model) {
  return model?.modelStatus?.toUpperCase() === 'NOT_DEPLOYED';
}

export function createTechnicalName (displayName) {
  if (!displayName) return;
  return displayName.toUpperCase().replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue').replace('ß', 'ss').replace('  ', ' ').replace(' ', '_');
}
