import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { FormattedMessage } from 'react-intl';
import './Confirmation.css';

export default function Confirmation (props) {
  const {
    id, onClose, onConfirmation, open, showTitle, title, message, okButtonText, okButtonProps, cancelButtonProps, cancelButtonText,
    className, showOkButtonProgress, skipCloseOnConfirmation, hideOkButton, disableOkButton,
    onSecondaryConfirmation, showSecondaryOkButton, secondaryOkButtonText, showSecondaryOkButtonProgress, secondaryOkButtonProps, loading, ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirmation();
    if (!skipCloseOnConfirmation) onClose();
  };

  const handleSecondaryOk = () => {
    onSecondaryConfirmation();
    if (!skipCloseOnConfirmation) onClose();
  };

  if (loading) {
    return (<Backdrop className='saveBackdrop' open={loading}>
      <CircularProgress color="primary"/>
    </Backdrop>);
  }

  return (
    <Dialog
      maxWidth="sm"
      id={'ConfirmationDialog' + id}
      PaperProps={{
        'aria-labelledby': 'ConfirmationDialogHeader' + id
      }}
      open={open}
      {...other}
      className={`confirmationDialog ${className}`}
    >
      {(showTitle === undefined || showTitle === true) &&
        <DialogTitle id={'ConfirmationDialogHeader' + id}>
          {title || <FormattedMessage id="confirmation.title" />
          }
        </DialogTitle>
      }
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} id={id + '-cancel'} color="primary" {...cancelButtonProps}>
          {cancelButtonText || <FormattedMessage id="cancel" />
          }
        </Button>
        {showSecondaryOkButton &&
        <div className="buttonWrapper circularProgressWrapper">
          <Button onClick={handleSecondaryOk} id={id + '-ok'} color="primary" disabled={showSecondaryOkButtonProgress} {...secondaryOkButtonProps}>
            {secondaryOkButtonText || <FormattedMessage id="ok" />
            }
          </Button>
          {showSecondaryOkButtonProgress && <CircularProgress size={32} style={{ marginLeft: '-1rem', marginTop: '-1rem' }} />}
        </div>
        }
        {!hideOkButton &&
        <div className="buttonWrapper circularProgressWrapper">
          <Button onClick={handleOk} id={id + '-ok'} color="primary" disabled={showOkButtonProgress || disableOkButton} {...okButtonProps}>
            {okButtonText || <FormattedMessage id="ok" />
            }
          </Button>
          {showOkButtonProgress && <CircularProgress size={32} style={{ marginLeft: '-1rem', marginTop: '-1rem' }} />}
        </div>
        }
      </DialogActions>
    </Dialog>
  );
}

Confirmation.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  onConfirmation: PropTypes.func,
  open: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.object,
  message: PropTypes.any,
  okButtonText: PropTypes.object,
  okButtonProps: PropTypes.object,
  cancelButtonText: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  showOkButtonProgress: PropTypes.bool,
  className: PropTypes.string,
  skipCloseOnConfirmation: PropTypes.bool,
  hideOkButton: PropTypes.bool,
  disableOkButton: PropTypes.bool,
  loading: PropTypes.bool,
  onSecondaryConfirmation: PropTypes.func,
  showSecondaryOkButton: PropTypes.bool,
  secondaryOkButtonText: PropTypes.object,
  showSecondaryOkButtonProgress: PropTypes.bool,
  secondaryOkButtonProps: PropTypes.object
};
